import React from 'react';
import styled from 'styled-components';
import { graphql, Link, useStaticQuery } from 'gatsby';


import fernando from '../assets/images/foto-fernando.jpg';

import Article from '../components/Article';
import SubPageStyles from '../styles/SubPageStyles';
import SocialLinks from '../components/SocialLinks';

const AlianzaStyles = styled.div`
  div {
    padding: 1rem 15%;
    background-color: #dcdcdca6;
    backdrop-filter: blur(52px);

    &.contact {
      background-color: transparent;
      backdrop-filter: none;
      div {
          margin: 1rem;
          background-color: transparent;
          backdrop-filter: none;
      }
    }

    &.header {
      padding: 1rem 3rem;
      background: transparent;
    }

    img {
      padding: 3rem;
      max-width: 680px;
      margin: 0 auto;
    }

    ul {
      text-align: left;
    }
  }

  @media (max-width: 800px) {
    height: auto;
    h1 {
      font-size: 3rem;
    }
    div {
      padding: 1rem 2.2rem;
      
      &.contact {
        padding: 1rem 0;
        div {
            margin: 0.5rem;
        }
      }

      &.header {
        padding: 1rem 2rem;
        background: transparent;
      }
      img {
        padding: 0;
        max-width: 260px;
        margin: 0 auto;
      }
    }
  }
`;

export default function ContactPage() {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          mail
        }
      }
    }
  `);

  const { mail } = site.siteMetadata;

  return (
    <AlianzaStyles>
      <Article title="Contacto">
        <>

          <img src={fernando}></img>
          <h2>Sobre mí</h2>
          <p>
            Soy un ingeniero de alimentos especializado en Administración de la
            Calidad Total y la Productividad. Poseo una amplia experiencia en
            este campo, remontándose al año de 1981, cuando comencé a trabajar
            en la industria manufacturera con productos Quaker y  Adams
             en cargos directivos, en los
            cuales, como parte de mis funciones principales debía reportar
            directamente a la presidencia de las compañías y a los directores
            regionales e internacionales los asuntos de Calidad, Medio Ambiente, Seguridad Industrial, Salud Ocupacional,
            Inocuidad Alimentaria, Desarrollo de Nuevos productos y Asuntos
            Regulatorios. Mi responsabilidad directa en estos asuntos era con la
            Región Andina, e igualmente, trabajaba en conjunto con otras
            filiales de las corporaciones en países como Brasil, Canadá, México,
            Estados Unidos e Inglaterra.
          </p>
          <br />
          <Link to="/sobre-mi#COACHING">
            <h3>SERVICIOS DE COACHING Y CONSULTORÍA EMPRESARIAL</h3>
          </Link>
          <br />
          <Link to="/sobre-mi#SERVICIOS">
            <h3>SERVICIOS DESTACADOS EN MI TRAYECTORIA</h3>
          </Link>
          <br />
          <Link to="/mision">
            <h3>Misión</h3>
          </Link>
          <br />

          <div className="contact">
            <a className="button" href={`mailto:${mail}`} alt={mail}>
              Envíame un correo
            </a>
            <SocialLinks showDesc={false} />
          </div>
        </>
      </Article>
    </AlianzaStyles>
  );
}
